import { AccountDocument, useAuth } from "@with-nx/auth";
import { FileCard, UploadedFile } from "@with-nx/file";
import { useAccountDocuments } from "@with-nx/hooks-n-helpers";
import {
  DesignedBadge,
  DesignedButton,
  DesignedIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import moment from "moment";
import { FC, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

const DocumentStatus: Record<string, string> = {
  uploaded: "Waiting Approval",
  approved: "Approved",
  expired: "Expired",
};

interface AccountDocumentTagProps {
  document?: AccountDocument;
}

const isDocumentUploaded = (document?: AccountDocument) =>
  document && document?.status && document.status !== "missing";

const AccountDocumentTag: FC<AccountDocumentTagProps> = ({ document }) => {
  return document && isDocumentUploaded(document) ? (
    <Box parse="d:flex a:center">
      <DesignedBadge
        label={DocumentStatus[document.status || ""]}
        color={document.status === "approved" ? "var(--background)" : undefined}
        background={document.status === "approved" ? "?primary" : undefined}
      />

      {!!document?.uploaded_at &&
        !!(document?.status !== "expired") &&
        !!document?.expiration_date && (
          <Box parse="ml:10">
            |{" "}
            {moment(document?.expiration_date).isBefore(moment())
              ? "expired "
              : "expires "}
            {moment(document?.expiration_date).fromNow()}
          </Box>
        )}
    </Box>
  ) : null;
};

export const AccountDocuments = () => {
  const { authState } = useAuth();
  const user = authState?.session?.user;

  const [driverLicense, _driverLicense] = useState<AccountDocument | undefined>(
    isDocumentUploaded(user?.drivers_license)
      ? {
          name: "drivers_license",
          ...user?.drivers_license,
        }
      : undefined
  );

  const [taxExemption, _taxExemption] = useState<AccountDocument | undefined>(
    isDocumentUploaded(user?.tax_exemption_document)
      ? {
          name: "tax_exemption",
          ...user?.tax_exemption_document,
        }
      : undefined
  );

  const [coi, _coi] = useState<AccountDocument | undefined>(
    isDocumentUploaded(user?.coi) ? { name: "coi", ...user?.coi } : undefined
  );

  const { upload, uploading, success, error, _error } = useAccountDocuments();

  const handleSubmit = async () => {
    await upload(driverLicense?.file, coi?.file, taxExemption?.file);

    const now = new Date();

    _driverLicense((previous) => ({
      ...previous,
      status: "uploaded",
      uploaded_at: now.toISOString(),
    }));

    _coi((previous) => ({
      ...previous,
      status: "uploaded",
      uploaded_at: now.toISOString(),
    }));

    _taxExemption((previous) => ({
      ...previous,
      status: "uploaded",
      uploaded_at: now.toISOString(),
    }));
  };

  return (
    <Box parse="mt:24">
      <Box parse="pa:20 br:5 mb:24" border="1px solid rgba(255,255,255,0.1)">
        <Rule color="var(--font2)" rule="lm" display="block" bottom={4}>
          Hardware
        </Rule>
        <Box parse="d:flex a:center mb:24">
          <DesignedIcon name="info" size={16} color="var(--font4)" />
          <Rule color="var(--font3)" rule="ls" display="block" left={4}>
            Only required for hardware rentals
          </Rule>
        </Box>
        <Row gutter={[36, 18]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Rule color="var(--font4)" rule="lt" display="block" bottom={12}>
              Driver License
            </Rule>

            <FileCard
              tag={<AccountDocumentTag document={driverLicense} />}
              uploaded={driverLicense}
              upload={(files?: UploadedFile[]) => {
                _error("");
                _driverLicense(files?.[0]);
              }}
              bottom={0}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Rule color="var(--font4)" rule="lt" display="block" bottom={12}>
              Certificate of Insurance
            </Rule>

            <FileCard
              tag={<AccountDocumentTag document={coi} />}
              uploaded={coi}
              upload={(files?: UploadedFile[]) => {
                _error("");
                _coi(files?.[0]);
              }}
              bottom={0}
            />
          </Col>
        </Row>
      </Box>

      <Box parse="pa:20 br:5 mb:24" border="1px solid rgba(255,255,255,0.1)">
        <Rule color="var(--font2)" rule="lm" display="block" bottom={24}>
          Taxation
        </Rule>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Rule color="var(--font4)" rule="lt" display="block" bottom={12}>
              Tax Exemption
            </Rule>

            <FileCard
              tag={<AccountDocumentTag document={taxExemption} />}
              uploaded={taxExemption}
              upload={(files?: UploadedFile[]) => {
                _error("");
                _taxExemption(files?.[0]);
              }}
              bottom={0}
            />
          </Col>
        </Row>
      </Box>

      {!!error && (
        <Box parse="pb:18">
          <Rule display="block" rule="lt" color="var(--negative)">
            {error}
          </Rule>
        </Box>
      )}

      {success && (
        <Box parse="pb:18">
          <Rule display="block" rule="lt" color="var(--positive)">
            Documents were uploaded successfully!
          </Rule>
        </Box>
      )}

      <Box parse="d:flex j:flex-end">
        <DesignedButton
          label="Upload Documents"
          press={handleSubmit}
          loading={uploading}
          disable={uploading}
        />
      </Box>
    </Box>
  );
};

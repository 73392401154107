"use client";

import { Auth, withAuthContext } from "@with-nx/auth";
import { useService } from "@with-nx/hooks-n-helpers";
import React, { createContext, useContext } from "react";
import { array, bool, InferType, mixed, number, object, string } from "yup";

const UseServerRegionTypeSchema = object({
  id: number().required(),
  enabled: bool(),
  name: string(),
  countries: array().of(
    object({
      code: string(),
      name: string(),
    })
  ),
  currency: object({
    name: string(),
    symbol: string(),
    code: string(),
  }).required(),
  meta: object({
    loading: bool(),
    error: bool(),
    code: mixed().oneOf(["us", "au", "uk"]),
  })
    .optional()
    .nullable(),
});

export type UseServerRegionType = InferType<typeof UseServerRegionTypeSchema>;

export type UseServerRegionMetaCode = "us" | "au" | "uk";

export const RegionContext = createContext<UseServerRegionType | undefined>(
  undefined
);

class RegionProviderClass extends React.PureComponent<
  {
    children: JSX.Element | JSX.Element[];
    auth?: Auth;
  },
  {
    region: UseServerRegionType | undefined;
    current?: boolean;
  }
> {
  constructor(props: { children: JSX.Element }) {
    super(props);

    this.state = {
      region: undefined,
    };
  }

  location = async (
    type: "ip-location" | "current" = "ip-location",
    token?: string,
    debug?: boolean
  ) => {
    if (this.state.current && type === "ip-location" && this.state.region) {
      return;
    }

    if (debug) {
      console.log("🔒", "Location", type, token);
    }

    const makeRequestToMicroservice = useService("microservice", {
      cache: 86_400_000,
      bypass: true,
    });

    let code: string | undefined = "us";

    const request = await makeRequestToMicroservice(
      "GET",
      `/ecommerce/regions/${type}`,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (await UseServerRegionTypeSchema.isValid(request)) {
      (request as UseServerRegionType)?.countries?.forEach((country) => {
        switch (country.code) {
          case "UK":
            code = "uk";
            break;
          case "GB":
            code = "uk";
            break;
          case "AU":
            code = "au";
            break;
          case "NZ":
            code = "au";
            break;
          default:
            break;
        }
      });

      this.setState({
        current: type === "current",
        region: {
          ...(request as UseServerRegionType),
          meta: {
            loading: false,
            error: false,
            code: code as UseServerRegionMetaCode,
          },
        },
      });

      console.log("🗺️ 🔒", type, {
        ...(request as UseServerRegionType),
        meta: {
          loading: false,
          error: false,
          code: code as UseServerRegionMetaCode,
        },
      });

      if ((request as UseServerRegionType)?.id !== 1) {
        /** The user seems to be on a different region, we need to force update the component. */
        this.forceUpdate();
      }
    } else {
      console.error("🗺️ 🔒", "Region Error", request);
    }
  };

  override async componentDidMount(): Promise<void> {
    const accessKeyLogin =
      !!this?.props?.auth?.authState?.session?.global_access_key ||
      !!this?.props?.auth?.authState?.session?.choreo_key;

    if (!accessKeyLogin && this?.props?.auth?.authState?.session?.token) {
      this.location("current", this?.props?.auth?.authState?.session?.token);
    } else {
      this.location("ip-location");
    }
  }

  override componentDidUpdate(prevProps: { auth?: Auth }): void {
    if (
      prevProps?.auth?.authState?.session?.token !==
      this?.props?.auth?.authState?.session?.token
    ) {
      console.log(
        "🔒",
        "Auth Update",
        this?.props?.auth?.authState?.session?.token
      );

      if (this?.props?.auth?.authState?.session?.token) {
        this.location(
          "current",
          this?.props?.auth?.authState?.session?.token,
          true
        );
      } else {
        this.location("ip-location");
      }
    }
  }

  override render() {
    return (
      <RegionContext.Provider
        value={
          this.state.region || {
            id: 1,
            enabled: true,
            name: "USA/Canada",
            currency: {
              name: "Dollar",
              symbol: "$",
              code: "USD",
            },
            countries: [
              {
                code: "US",
                name: "United States of America",
              },
              {
                code: "CA",
                name: "Canada",
              },
            ],
            meta: {
              code: "us",
            },
          }
        }
      >
        {this.props.children}
      </RegionContext.Provider>
    );
  }
}

export const RegionProvider = withAuthContext(RegionProviderClass);

export const useServerRegion = () => {
  const context = useContext(RegionContext);

  if (!context) {
    throw new Error("useServerRegion must be used within an `RegionProvider`");
  }

  return context;
};

export default useServerRegion;

import { createContext, useContext, useEffect, useState } from "react";

import { useMediaQuery } from "./useMediaQuery";

export type Mobile = {
  mobile: boolean;
};

export const MobileContext = createContext<Mobile | undefined>(undefined);

export const MobileProvider = ({
  children,
  width = 767,
  initialMobile = false,
}: {
  children: JSX.Element | JSX.Element[];
  initialMobile?: boolean;
  width?: number;
}) => {
  const [mobile, _mobile] = useState(initialMobile);

  const query = useMediaQuery(width, (s) => _mobile(s));

  /* This approach is to prevent hydration errors from NextJS. */
  useEffect(() => {
    if (!initialMobile) {
      _mobile(query.smaller);
    }
  }, [Boolean(query.smaller), initialMobile]);

  useEffect(() => {
    const ww = window.innerWidth;

    if (ww <= width && !mobile) {
      _mobile(true);
    } else if (ww > width && mobile) {
      _mobile(false);
    }
  }, []);

  return (
    <MobileContext.Provider
      value={{
        mobile,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = (width?: number, origin?: string) => {
  const context = useContext(MobileContext);

  if (!context) {
    throw new Error(
      `useMobile must be used within an MobileProvider - ${origin}`
    );
  }

  return Boolean(context.mobile);
};

export const useCustomMobile = (width: number = 767) => {
  const context = useContext(MobileContext);

  if (!context) {
    throw new Error(`useCustomMobile must be used within a MobileProvider`);
  }

  const [mobile, _mobile] = useState(context.mobile);

  useEffect(() => {
    const handleResize = () => {
      _mobile(window.innerWidth <= width);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return mobile;
};
export default useMobile;

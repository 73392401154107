import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Tooltip } from "@with-nx/editor-ui";
import {
  DropCollection,
  useDrop,
  useDropCollection,
  useHazel,
  useUserDrop,
} from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCard,
  DesignedIcon,
  DesignedInput,
  DesignedTag,
  LoadingShimmer,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import {
  FiCheckSquare,
  FiDownloadCloud,
  FiPlusSquare,
  FiX,
  FiXSquare,
} from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";
import SafeAreaAbsolutePosition from "../safe-area-absolute-position/safe-area-absolute-position";

interface ReusableCollectionItemProps {
  mode: "active" | "pending" | "none";
  loading?: boolean;
  open?: boolean;
  name: string;
  bottom?: number;
  properties?: {
    [key: string]: unknown;
  };
  onActivate?: () => void;
  onDisable?: () => void;
  minimal?: boolean;
  id?: string;
}

interface ReusableCollectionItemDropProps {
  id: string;
  collection?: DropCollection;
  remove: () => void;
}

export const ReusableCollectionItemDrop = (
  props: ReusableCollectionItemDropProps
) => {
  const drop = useDrop(props.id);

  if (drop.loading) {
    return <LoadingShimmer parse="br:10 pt:56.25%" />;
  }

  return (
    <DesignedCard
      size="16x9"
      image={drop.loading ? undefined : drop.data?.thumbnail}
      video={drop.loading ? undefined : drop.data?.previewVideo}
      gradient={{
        direction: "top-bottom",
      }}
      outside={
        drop.loading
          ? undefined
          : {
              title: drop.data?.name?.split("(")?.[0]?.trim(),
            }
      }
      inside={{
        controls: [<DropCollectionDropControls press={props.remove} />],
      }}
    />
  );
};

export const ReusableCollectionItemUserDrop = (
  props: ReusableCollectionItemDropProps
) => {
  const drop = useUserDrop(props.id);

  if (drop.loading) {
    return <LoadingShimmer parse="br:10 pt:56.25%" />;
  }

  return (
    <DesignedCard
      size="16x9"
      image={drop.loading ? undefined : drop.data?.thumbnail}
      video={drop.loading ? undefined : drop.data?.previewVideo}
      gradient={{
        direction: "top-bottom",
      }}
      outside={
        drop.loading
          ? undefined
          : {
              title: drop.data?.name?.split("(")?.[0]?.trim(),
            }
      }
      inside={{
        controls: [<DropCollectionDropControls press={props.remove} />],
      }}
    />
  );
};

export const ReusableCollectionItem = (props: ReusableCollectionItemProps) => {
  const { name, bottom = 24, open, id } = props;

  const [expand, _expand] = useState(open === undefined ? true : open);
  const [edit, _edit] = useState(false);
  useEffect(() => {
    _expand(open === undefined ? true : open);
  }, [open]);

  const collection = useDropCollection(id || "");
  const [_name, __name] = useState(name);
  const hazel = useHazel();

  const mode = collection?.data
    ? [
        ...(collection?.data?.dropIds || []),
        ...(collection?.data?.userDropIds || []),
      ].length === 0
      ? "none"
      : collection.data?.active
      ? "active"
      : "pending"
    : props.mode;

  const badge = (() => {
    switch (mode) {
      case "active":
        return {
          background: "var(--positive)",
          color: "white",
          label: "Active",
        };

      case "pending":
        return {
          background: "var(--warning)",
          color: "white",
          label: "Disabled",
        };

      default:
        return {
          background: "var(--entity)",
          color: "white",
          label: "Empty",
        };
    }
  })();

  return (
    <MobileViewSwitcher
      render={(mobile) => (
        <>
          <Box
            n="container"
            parse="d:flex pa:20 c:#14181D br:10"
            bottom={bottom}
          >
            <Box
              n="mode"
              parse="h:50 w:4 br:999 mr:16 mnw:4"
              color={
                mode === "active"
                  ? Colors.positive
                  : mode === "pending"
                  ? Colors.warning
                  : Colors.entity
              }
            ></Box>
            <Box n="sub-container" parse="f:1">
              <Box
                n="inline-container"
                parse={`w:100% d:flex a:${
                  mobile ? "flex-start" : "center"
                } fd:${mobile ? "column" : "row"}`}
              >
                <Box n="image-and-title" parse="f:1 d:flex a:center">
                  <Box css="d:inline-flex a:center" bottom={2}>
                    <Rule rule="lm" color={Colors.font1} right={8}>
                      {collection.data?.name || name}
                    </Rule>
                    <DesignedTag
                      size="tiny"
                      label={badge.label}
                      color={badge.color}
                      background={badge.background}
                    />
                  </Box>
                </Box>
                <Box
                  n="simple-details"
                  parse={`f:1 pl:${mobile ? 0 : 25} mt:${
                    mobile ? 6 : 0
                  } d:flex a:center j:${
                    mobile ? "flex-start" : "flex-end"
                  } f:1`}
                >
                  <Box css="d:inline-flex a:center">
                    <Tooltip position="top-center" label="Download">
                      <DesignedButton
                        icon={
                          <FiDownloadCloud
                            size={20}
                            color={
                              collection.data?.active
                                ? "var(--primary)"
                                : "rgba(255,255,255,0.5)"
                            }
                            title="download icon"
                          />
                        }
                        square={true}
                        size={mobile ? "small" : "large"}
                        theme={collection.data?.active ? "tetriary" : "ghost"}
                        press={() => {
                          window.open(hazel.download, "_blank");
                        }}
                        properties={{ right: 10 }}
                      />
                    </Tooltip>
                    <Tooltip position="top-center" label="Edit Drop Collection">
                      <DesignedButton
                        icon="pencil/bold"
                        square={true}
                        size={mobile ? "small" : "large"}
                        theme={collection.data?.active ? "tetriary" : "ghost"}
                        press={() => _edit(true)}
                        properties={{ right: 10 }}
                      />
                    </Tooltip>
                    <Tooltip
                      position="top-center"
                      label={
                        collection.data?.active
                          ? "Disable Drop Collection"
                          : "Activate Drop Collection"
                      }
                    >
                      <DesignedButton
                        icon={
                          collection.data?.active ? (
                            <FiXSquare
                              size={20}
                              color="var(--primary)"
                              title="x icon"
                            />
                          ) : (
                            <FiCheckSquare
                              size={20}
                              color="var(--primary)"
                              title="check icon"
                            />
                          )
                        }
                        square={true}
                        size={mobile ? "small" : "large"}
                        theme="tetriary"
                        press={() => {
                          if (!collection.data?.active) {
                            collection.helpers.collection.activate();
                          } else {
                            collection.helpers.collection.disable();
                          }
                        }}
                        properties={{ right: 10 }}
                      />
                    </Tooltip>
                    <DesignedButton
                      icon={expand ? "up/bold" : "down/bold"}
                      square={true}
                      size={mobile ? "small" : "large"}
                      theme="secondary"
                      press={() => _expand(!expand)}
                    />
                  </Box>
                </Box>
              </Box>
              {expand &&
              [
                ...(collection?.data?.dropIds || []),
                ...(collection?.data?.userDropIds || []),
              ].length > 0 ? (
                <Box
                  n="expanded-details"
                  parse="mt:16 pt:16"
                  style={{ borderTop: "1px solid var(--accent)" }}
                >
                  <Row gutter={[18, 18]}>
                    {collection.loading ? (
                      <>
                        {[...Array(3)].map((i, ii) => (
                          <Col xs={24} sm={24} md={8} key={ii}>
                            <LoadingShimmer parse="br:10 pt:56.25%" />
                          </Col>
                        ))}
                      </>
                    ) : (
                      <>
                        {collection.data &&
                          collection?.data?.dropIds.map((id) => (
                            <Col xs={24} sm={24} md={8} key={id}>
                              <ReusableCollectionItemDrop
                                id={id.toString()}
                                collection={collection.data || undefined}
                                remove={() =>
                                  collection.helpers.drop.remove([id])
                                }
                              />
                            </Col>
                          ))}
                        {collection.data &&
                          collection?.data?.userDropIds.map((id) => (
                            <Col xs={24} sm={24} md={8} key={id}>
                              <ReusableCollectionItemUserDrop
                                id={id.toString()}
                                collection={collection.data || undefined}
                                remove={() =>
                                  collection.helpers.user.remove([id])
                                }
                              />
                            </Col>
                          ))}
                      </>
                    )}
                  </Row>
                  <Box css="d:flex a:center j:flex-end">
                    <Tooltip
                      label={id?.toString() || ""}
                      position="bottom-center"
                    >
                      <Box parse="h:24 w:24 ml:12" />
                    </Tooltip>
                  </Box>
                </Box>
              ) : undefined}
            </Box>
          </Box>
          <Modal
            isOpen={edit}
            blockScrollOnMount={false}
            onClose={() => _edit(false)}
            size={{ base: "sm", md: "lg" }}
          >
            <ModalOverlay />
            <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
              <ModalHeader color="var(--font1)">
                Edit Drop Collection
              </ModalHeader>
              <ModalBody>
                <DesignedInput
                  label="New Collection Name"
                  value={_name}
                  change={(_name) => __name(_name)}
                />
                <Box css="d:flex a:center">
                  <DesignedButton
                    label="Save"
                    theme="primary"
                    press={() => {
                      _edit(false);
                      collection.helpers.collection.edit(_name);
                    }}
                    properties={{
                      right: 12,
                    }}
                  />
                  <DesignedButton
                    label="Delete Drop Collection"
                    theme="tetriary-accent"
                    press={() => {
                      _edit(false);
                      collection.helpers.collection.remove();
                    }}
                    properties={{
                      right: 12,
                    }}
                  />
                </Box>
              </ModalBody>

              <ModalFooter />
            </ModalContent>
          </Modal>
        </>
      )}
    />
  );
};

export const UserUploadDropControlCollectionItem = ({
  label,
  press,
  active,
}: {
  label: string;
  press: () => unknown;
  active?: boolean;
}) => {
  const [hover, _hover] = useState(false);

  return (
    <Box
      parse={`d:flex a:center pa:8 br:5 c:${
        hover || active ? "?accent" : "transparent"
      }`}
      native={{
        onMouseEnter: () => _hover(true),
        onMouseLeave: () => _hover(false),
      }}
      press={press}
    >
      {active ? (
        <DesignedIcon size={16} color="white" name="check/bold" />
      ) : undefined}{" "}
      <Rule parse="!lt" left={active ? 8 : 0}>
        {label}
      </Rule>
    </Box>
  );
};

export const UserUploadDropControls = ({
  remove,
  add,
  collections,
  id,
}: {
  remove?: () => unknown;
  add?: (collection: string) => unknown;
  collections?: DropCollection[];
  id: string | number;
}) => {
  const [_remove, __remove] = useState(false);
  const [_add, __add] = useState(false);

  const empty = collections?.length === 0;

  return (
    <Box css="d:inline-flex a:center">
      <Box parse="p:relative ml:8">
        <FiPlusSquare
          color={_add ? "var(--primary)" : "white"}
          onClick={() => __add(true)}
          size={20}
          title="plus icon"
        />
        {_add ? (
          <SafeAreaAbsolutePosition position="bottom-right">
            <Box
              parse="pa:12 c:?foreground br:10 w:256"
              native={{
                onMouseLeave: () => __add(false),
              }}
            >
              {empty ? (
                <>
                  <Rule parse="!ls d:block mb:4 c:?font1">No Collections</Rule>
                  <Rule parse="!lt c:?font3" size={13}>
                    Do you want to create your first collection?
                  </Rule>
                  <Box parse="d:flex a:center mt:4">
                    <DesignedButton
                      theme="primary"
                      size="small"
                      label="Create Collection"
                      properties={{ right: 4 }}
                      href="/profile/my-collections"
                    />
                  </Box>
                </>
              ) : (
                <>
                  {collections?.map((item) => (
                    <UserUploadDropControlCollectionItem
                      active={item.userDropIds.includes(
                        typeof id === "string" ? parseInt(id) : id
                      )}
                      label={item.name}
                      key={item.id}
                      press={() => add?.(item.id.toString())}
                    />
                  ))}
                </>
              )}
            </Box>
          </SafeAreaAbsolutePosition>
        ) : undefined}
      </Box>
      <Box parse="p:relative ml:8">
        <FiX
          onClick={() => __remove(true)}
          color={_remove ? "var(--negative)" : "white"}
          size={20}
          title="x icon"
        />
        {_remove ? (
          <SafeAreaAbsolutePosition position="bottom-right">
            <Box
              parse="pa:12 i:4 c:?foreground br:10 w:256"
              native={{
                onMouseLeave: () => __remove(false),
              }}
            >
              <Rule parse="!ls d:block mb:4 c:?font1">Delete</Rule>
              <Rule parse="!lt c:?font3" size={13}>
                Are you sure you want to delete this upload? This action cannot
                be undone.
              </Rule>
              <Box parse="d:flex a:center mt:4">
                <DesignedButton
                  theme="negative"
                  size="small"
                  label="Delete"
                  properties={{ right: 4 }}
                  press={remove}
                />
                <DesignedButton
                  theme="tetriary-accent"
                  size="small"
                  label="Cancel"
                  press={() => __remove(false)}
                />
              </Box>
            </Box>
          </SafeAreaAbsolutePosition>
        ) : undefined}
      </Box>
    </Box>
  );
};

const DropCollectionDropControls = ({ press }: { press: () => unknown }) => {
  const [remove, _remove] = useState(false);

  return (
    <Box css="d:inline-flex a:center">
      <Box parse="p:relative ml:8">
        <FiX
          onClick={() => _remove(true)}
          color={remove ? "var(--negative)" : "white"}
          size={20}
        />
        {remove ? (
          <SafeAreaAbsolutePosition position="bottom-right">
            <Box
              parse="pa:12 c:?foreground br:10 w:256"
              native={{
                onMouseLeave: () => _remove(false),
              }}
            >
              <Rule parse="!ls d:block mb:4 c:?font1">Remove</Rule>
              <Rule parse="!lt c:?font3" size={13}>
                Are you sure you want to remove this drop? This action cannot be
                undone.
              </Rule>
              <Box parse="d:flex a:center mt:4">
                <DesignedButton
                  theme="negative"
                  size="small"
                  label="Delete"
                  properties={{ right: 4 }}
                  press={() => {
                    press();
                    _remove(false);
                  }}
                />
                <DesignedButton
                  theme="tetriary-accent"
                  size="small"
                  label="Cancel"
                  press={() => _remove(false)}
                />
              </Box>
            </Box>
          </SafeAreaAbsolutePosition>
        ) : undefined}
      </Box>
    </Box>
  );
};

export const SearchDropControlCollectionItem = ({
  label,
  press,
  active,
}: {
  label: string;
  press: () => unknown;
  active?: boolean;
}) => {
  const [hover, _hover] = useState(false);

  return (
    <Box
      parse={`d:flex a:center pa:8 br:5 c:${
        hover || active ? "?accent" : "transparent"
      }`}
      native={{
        onMouseEnter: () => _hover(true),
        onMouseLeave: () => _hover(false),
      }}
      press={press}
    >
      {active ? (
        <DesignedIcon size={16} color="white" name="check/bold" />
      ) : undefined}{" "}
      <Rule parse="!lt" left={active ? 8 : 0}>
        {label}
      </Rule>
    </Box>
  );
};

export default ReusableCollectionItem;

import { CustomEventTracker } from "@with-nx/analytics";
import {
  CampaignHelper,
  createAccount,
  createSession,
  createSessionByKey,
  createSessionToken,
  resetPassword,
  Session,
  useAuth,
} from "@with-nx/auth";
import {
  ShoppingCartMethodProductType,
  ShoppingCartMethods,
} from "@with-nx/ecommerce";
import { passwordStrength } from "@with-nx/hooks-n-helpers";
import { useServerRegion, UseServerRegionMetaCode } from "@with-nx/region";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export interface AuthFormFields {
  name?: string;
  email?: string;
  organization?: string;
  location?: number;
  type?: number;
  phone?: string;
  state?: string;
  country?: string;
}

interface AuthFormProps {
  initialValues?: AuthFormFields;
}

export const useAuthForm = ({
  initialValues = {},
}: Partial<AuthFormProps> = {}) => {
  const router = useRouter();
  const server = useServerRegion();

  const { login } = useAuth();

  const [loading, _loading] = useState(false);
  const [error, _error] = useState<string>("");
  const [positive, _positive] = useState<string>("");

  const [calendly, _calendly] = useState<Session | undefined>(undefined);

  const [type, _type] = useState<number>(11);
  const [location, _location] = useState<number>(server?.id || null);
  const [organization, _organization] = useState<string>("");
  const [phone, _phone] = useState<string>(initialValues?.phone);
  const [state] = useState<string>(initialValues?.state);
  const [country] = useState<string>(
    initialValues?.country || "🇺🇸 United States"
  );

  const [name, _name] = useState<string>("");
  const [email, _email] = useState<string>("");
  const [password, _password] = useState<string>("");
  const [repeat, _repeat] = useState<string>("");
  const [key, _key] = useState<string>("");
  const [token, _token] = useState<string>("");

  const [form, _form] = useState<"login" | "register" | "forgot" | "key">(
    "login"
  );
  const [shouldNotRedirectLogin, _shouldNotRedirectLogin] = useState(false);
  const [shouldOpenURL, _shouldOpenURL] = useState<string>();

  useEffect(() => {
    _name(initialValues?.name || "");
    _email(initialValues?.email || "");
    _password("");
    _repeat("");
    _error("");
    _positive("");
    _location(initialValues?.location || NaN);
    _organization(initialValues?.organization || "");
    _type(initialValues?.type || NaN);
    _phone(initialValues?.phone);
  }, [form, JSON.stringify(initialValues)]);

  const validate = ({
    type,
    value,
  }: {
    type: "email" | "password" | "name";
    value: string;
  }) => {
    switch (type) {
      case "email":
        return (() => {
          const regex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

          if (value.match(regex)) {
            return true;
          }
          return false;
        })();

      case "password":
        return (() => {
          const strength = passwordStrength(value);

          if (
            strength.length >= 8 &&
            strength.contains.includes("lowercase") &&
            strength.contains.includes("uppercase") &&
            strength.contains.includes("number")
          ) {
            return true;
          }

          return false;
        })();

      case "name":
        return (() => {
          if (!value) return false;

          const words = value.trim().split(" ");
          const nameHasAtLeastTwoWords = words.length > 1;

          return nameHasAtLeastTwoWords;
        })();
    }

    return false;
  };

  const helpers = {
    login: () => {
      if (
        validate({
          type: "email",
          value: email,
        })
      ) {
        _loading(true);
        createSession(email, password)
          .then((session) => {
            login(session);
            logic(session);
            _loading(false);
            CustomEventTracker.track("auth/login");
          })
          .catch(() => {
            _loading(false);
            _error("Email and/or password invalid.");
          });
      } else {
        _error("Please enter a valid email address.");
      }
    },
    register: async () => {
      if (location === null) {
        _error("Please select a region.");
      } else if (type === null) {
        _error("Please select a business type.");
      } else if (organization?.length <= 4) {
        _error("Please enter a valid organization name.");
      } else if (
        !validate({
          type: "name",
          value: name,
        })
      ) {
        _error("Please enter your full name");
      } else if (password !== repeat) {
        _error("Passwords do not match.");
      } else if (
        !validate({
          type: "password",
          value: password,
        })
      ) {
        _error(
          "Your password should contain at least one lowercase, one uppercase and a number. Password should be minimum 8 characters."
        );
      } else if (
        !validate({
          type: "email",
          value: email,
        })
      ) {
        _error("Please enter a valid email address");
      } else {
        _loading(true);

        const request = await createAccount(name, email, password, {
          organization: organization,
          business_type: type,
          region_id: location,
          phone_number: phone || initialValues?.phone,
          state: state || initialValues?.state,
          country: country || initialValues?.country,
        });

        if (request.success) {
          CustomEventTracker.track("auth/register");

          try {
            const session = await createSession(email, password);
            login(session);
            logic(session);
            CustomEventTracker.track("auth/login");
          } catch (error) {
            console.error(error);
          }

          _error("");
          _positive(
            "Successfully registered, redirecting you to your account."
          );
        } else {
          _error(request.error);
        }

        _loading(false);
      }
    },
    forgot: () => {
      if (
        validate({
          type: "email",
          value: email,
        })
      ) {
        (async () => {
          _loading(true);

          await resetPassword(email);

          _positive("Check your inbox for password reset instructions.");

          _loading(false);
          CustomEventTracker.track("auth/forgot");
        })();
        _error("");
      } else {
        _error("Please enter a valid email address.");
      }
    },
    key: (type: "key" | "global_access_key", retry: boolean = false) => {
      (async () => {
        _loading(true);

        try {
          const body = {
            [type === "key" ? "choreo_key" : "global_access_key"]: key,
          };

          const session = await createSessionByKey(body);

          login(session);

          if (session?.choreo_key && session?.choreo_key?.slug) {
            router.push(
              `/library/choreography-guides/${session.choreo_key.slug}`
            );
          } else {
            router.push(`/library/rentals`);
          }

          CustomEventTracker.track(
            `auth/${type == "global_access_key" ? "global_access_" : ""}key`
          );
        } catch (error) {
          if (retry === false) {
            return await helpers.key(
              type === "key" ? "global_access_key" : "key",
              true
            );
          }

          console.error(error);
          _error(error);
        } finally {
          _loading(false);
        }
      })();
    },
    access: (t?: string) => {
      createSessionToken(t || token)
        .then((session) => {
          login(session);
          logic(session);
          _loading(false);
          CustomEventTracker.track("auth/key");
        })
        .catch(() => {
          _loading(false);
          _error("Email and/or password invalid.");
        });
    },
  };

  const logic = useCallback(
    async (session: Session) => {
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get("redirect");
      const open = params.get("open");
      const product = params.get("product");
      const productType = params.get("type");

      const proLicense = params.get("proLicense");
      const rentalStart = params.get("rentalStart");
      const rentalEnd = params.get("rentalEnd");

      CampaignHelper.attach(session.token);

      const options = {
        proLicense: Boolean(proLicense),
        rentalStart: rentalStart,
        rentalEnd: rentalEnd,
      };

      if (open === "representative") {
        router.push("/contact");
        return;
      }

      if (product?.length > 0) {
        await ShoppingCartMethods.addProduct({
          id: product,
          token: session.token,
          options,
          type: productType as ShoppingCartMethodProductType,
        });

        router.push("/checkout");
        return;
      }

      if (open === "calendly") {
        router.push("/contact/calendar");
        return;
      }

      if (open === "back") {
        router.back();
        return;
      }

      if (shouldOpenURL) {
        window.open(shouldOpenURL, "_blank");
      }

      const loginIsFromGatedContent =
        router.route.includes("scenic-projections") ||
        router.route.includes("/sale") ||
        router.route.includes("/contact/start");

      if (!loginIsFromGatedContent) {
        router.push(redirect || "/profile/account");
      }
    },
    [shouldNotRedirectLogin, shouldOpenURL]
  );

  return {
    loading,
    _loading,

    name,
    _name,

    email,
    _email,

    type,
    _type,

    location,
    _location,

    organization,
    _organization,

    error,
    _error,

    password,
    _password,

    repeat,
    _repeat,

    key,
    _key,

    token,
    _token,

    form,
    _form,

    positive,
    _positive,

    calendly,
    _calendly,

    shouldNotRedirectLogin,
    _shouldNotRedirectLogin,

    shouldOpenURL,
    _shouldOpenURL,

    phone,
    _phone,

    login: helpers.login,
    register: helpers.register,
    forgot: helpers.forgot,
    keyLogin: helpers.key,
    publicAccessLogin: helpers.access,
  };
};

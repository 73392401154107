import { useMobile } from "@with-nx/hooks-n-helpers";
import exp from "constants";

export const TABLET_BREAKPOINT = 1023;
export const LARGE_DESKTOP_BREAKPOINT = 1440;

interface MobileViewSwitcherProps {
  width?: number;
  render: (mobile: boolean) => JSX.Element;
}

export const MobileViewSwitcher = ({
  render,
  width,
}: MobileViewSwitcherProps) => {
  const mobile = useMobile(width || TABLET_BREAKPOINT);

  return render(mobile);
};

export default MobileViewSwitcher;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";
import Toast from "react-hot-toast";

import useService from "./useService";
import { getSingleDrop } from "./useCollections";

export type UseOrdersOrder = {
  id: number | string;
  type:
    | "scenic-projections"
    | "choreo-guides"
    | "digital-drop"
    | "projector"
    | "professional"
    | "guide-package";
  professional?: boolean;
  name: string;
  slug?: string;
  start?: string;
  end?: string;
  active: boolean;
  ended: boolean;
  download?: number | string;
  image?: string | undefined;
};

export const useLegacyDownloads = (id?: number | false) => {
  const auth = useAuth();

  const download = async () => {
    if (!id) {
      Toast.error(
        "0x0001 There was a problem downloading your file. Please try again later."
      );
      return;
    }

    const makeRequestToAccounts = useService("accounts", {
      bypass: true,
    });

    const response = await makeRequestToAccounts(
      "GET",
      `/api/v2/downloads/${id}/download`,
      {},
      {
        Authorization: `Token: ${auth.authState.session?.token}`,
      }
    );

    if (response?.["errors"]) {
      if (response?.["errors"]?.[0]?.title) {
        Toast.error(response?.["errors"]?.[0]?.title);
      } else {
        Toast.error(
          "0x0002 There was a problem downloading your file. Please try again later."
        );
      }
      return;
    }

    const link = document.createElement("a");
    link.download;
    link.download = "Download Package";
    link.href = response?.["package"]?.["url"];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { download };
};

export const useDownloads = (id?: number | string | false) => {
  const auth = useAuth();

  const download = async (
    type: "package" | "layers" | "global-assets" = "package"
  ) => {
    if (!id) {
      Toast.error(
        "0x0001 There was a problem downloading your file. Please try again later."
      );
      return;
    }

    const makeRequestToAccounts = useService("accounts", {
      bypass: true,
    });

    const response = await makeRequestToAccounts(
      "GET",
      `/api/v2/downloads/pro_license/${id}`,
      {},
      {
        Authorization: `Token: ${auth.authState.session?.token}`,
      }
    );

    if (response?.["errors"]) {
      if (response?.["errors"]?.[0]?.title) {
        Toast.error(response?.["errors"]?.[0]?.title);
      } else {
        Toast.error(
          "0x0002 There was a problem downloading your file. Please try again later."
        );
      }
      return;
    }

    const d = (t: string, u: string) => {
      const link = document.createElement("a");
      link.download;
      link.download = t;
      link.href = u;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    if (response?.["urls"]?.collections_presigned_url) {
      switch (type) {
        case "package":
          d(
            "professional-license.zip",
            response?.["urls"]?.collections_presigned_url
          );
          break;
        case "layers":
          d("layers.zip", response?.["urls"]?.layers_presigned_url);
          break;
        case "global-assets":
          d(
            "global-assets.zip",
            response?.["urls"]?.global_assets_presigned_url
          );
          break;
      }
    } else {
      Toast.error(
        "0x0003 There was a problem downloading your file. Your package may not be ready. Please try again later."
      );
    }
  };

  return { download };
};

export const useRentals = () => {
  const makeRequestToAccounts = useService("accounts", {
    cache: 60_000,
    bypass: true,
  });
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<UseOrdersOrder[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const temporary: UseOrdersOrder[] = [];

    if (!auth.authState.session?.user.id) {
      return;
    }

    (async () => {
      setLoading(true);
      setError(false);

      try {
        const [rentals, choreos, professionals, guides] = await Promise.all([
          makeRequestToAccounts(
            "GET",
            "/api/v2/stageplayer/rentals",
            {
              include: "thumbnail",
            },
            {
              Authorization: `Token: ${auth.authState.session?.token}`,
            }
          ).catch(() => {}),
          makeRequestToAccounts(
            "GET",
            "/api/v2/choreo-guides",
            {
              "filter[library]": "1",
              include: "show",
            },
            {
              Authorization: `Token: ${auth.authState.session?.token}`,
            }
          ).catch(() => {}),
          makeRequestToAccounts(
            "GET",
            "/api/v2/show_packages/rentals",
            {},
            {
              Authorization: `Token: ${auth.authState.session?.token}`,
            }
          ).catch(() => {}),
          makeRequestToAccounts(
            "GET",
            "/api/v2/guides/rentals",
            {},
            {
              Authorization: `Token: ${auth.authState.session?.token}`,
            }
          ).catch(() => {}),
        ]);

        await Promise.all(
          rentals?.["data"]?.map(async (item: any) => {
            const aid = item?.relationships?.thumbnail?.data?.id;

            let image = await (async () => {
              if (
                item?.attributes?.kind === "DigitalDrops::Rental" &&
                item?.attributes?.["digital-drops-scenes"]?.length > 0
              ) {
                const singleDropId =
                  item?.attributes?.["digital-drops-scenes"]?.[0];
                const singleDrop = await getSingleDrop(singleDropId);

                return singleDrop?.thumbnail;
              }

              return rentals?.["included"]?.find(
                (a: any) => a.type === "attachments" && a?.id === aid
              )?.attributes?.url;
            })();

            temporary.push({
              id: item.id,
              type:
                item?.attributes?.kind === "DigitalDrops::Rental"
                  ? "digital-drop"
                  : "scenic-projections",
              name: item.attributes.title,
              start: item.attributes["start-date"],
              end: item.attributes["end-date"],
              image,
              ended: item.attributes["end-date"] < new Date().toISOString(),
              active: item.attributes["start-date"] <= new Date().toISOString(),
              professional: Boolean(item.attributes?.["is-professional"]),
            });

            return true;
          })
        );

        choreos?.["data"]?.map((item: any) => {
          temporary.push({
            id: item.id,
            slug: item.attributes.slug,
            type: "choreo-guides",
            name: choreos?.["included"].filter(
              (_item: any) => _item.id === item.relationships.show.data.id
            )[0].attributes.title,
            active: Boolean(item?.attributes?.["has-access"]),
            ended: !item?.attributes?.["has-access"],
            image: item?.attributes?.["marketing-image"],
          });
        });

        professionals?.["data"]?.map((item: any) => {
          temporary.push({
            id: item.id,
            type: "professional",
            name: item.attributes.title,
            start: item.attributes["start-date"],
            end: item.attributes["end-date"],
            download: item.attributes?.download?.id,
            ended: item.attributes["end-date"] < new Date().toISOString(),
            active: item.attributes["start-date"] <= new Date().toISOString(),
            image: item.attributes?.["show-logo"],
          });
        });

        guides?.["data"]?.map((item: any) => {
          temporary.push({
            id: item.id,
            type: "guide-package",
            name: item?.guide_package?.title,
            active: item?.start_date <= new Date().toISOString(),
            ended: item?.end_date < new Date().toISOString(),
            start: item?.start_date,
            end: item?.end_date,
          });
        });

        setOrders(temporary);
      } catch (error) {
        setError(true);
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [auth.authState.session?.user.id]);

  return { rentals: orders, loading, error };
};

export const useCSV = () => {
  const auth = useAuth();

  const helpers = {
    csv: (csv: string, name?: string) => {
      if (!document) {
        return;
      }

      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name || "download.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    },
  };
  const methods = {
    download: async (id: string) => {
      const makeRequestToAccounts = useService("accounts", {
        text: true,
        bypass: true,
      });

      const csv = (await makeRequestToAccounts(
        "GET",
        `/api/v2/scenic_projections_rentals/${id}/cue_sheet`,
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      )) as unknown as string;

      helpers.csv(csv, `${id}.csv`);
    },
  };

  return { methods };
};

export default useRentals;

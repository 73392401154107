import { Currency, usePricing } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  DesignedIcon,
  LoadingShimmer,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

export const DigitalBackdropPricing = ({
  press,
  cta,
}: {
  press?: () => unknown;
  cta?: boolean;
}) => {
  const region = useServerRegion();
  const digitalDropPricing = usePricing(
    {
      type: "DIGITAL_DROP",
      region: region?.id,
    },
    !!region?.id
  );

  const individualPricing = digitalDropPricing
    ? Currency.formatInteger(
        Number(digitalDropPricing?.price) || 0,
        region?.currency?.code || "USD"
      )
    : undefined;

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        {!individualPricing ? (
          <LoadingShimmer parse="br:10 pa:24 h:100%" />
        ) : (
          <ReusableGlossBox
            parse="pa:24 br:10 oa:hidden h:100%"
            mode="top-left-blue"
          >
            <Box parse="d:flex fd:column j:space-between h:100%">
              <Box>
                <Rule parse="!hs d:block mb:24">Individual Backdrops</Rule>

                <Box parse="d:flex a:baseline mt:16 mb:24">
                  <Rule rule="ll" size={48} line={56}>
                    {individualPricing}
                  </Rule>
                  <Rule rule="lm" parse="ml:5">
                    /each/per month
                  </Rule>
                </Box>

                <Rule rule="lm" parse="d:block mt:24 mb:12 c:?font3">
                  Features:
                </Rule>

                <Row gutter={[10, 10]}>
                  {[
                    "Pay per Backdrop",
                    "StagePlayer Software",
                    "Online Show Setup",
                    "Vignette and Layers",
                  ].map((item) => (
                    <Col key={item} xs={24}>
                      <Box parse="d:flex a:center" key={item}>
                        <DesignedIcon
                          name="check/bold"
                          color="var(--positive)"
                          size={24}
                        />
                        <Rule rule="lm" parse="d:block ml:10 c:?font1">
                          {item}
                        </Rule>
                      </Box>
                    </Col>
                  ))}
                </Row>
              </Box>

              <DesignedButton
                press={press}
                label="Search All Backdrops"
                properties={{
                  style: {
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  },
                }}
                container={{
                  style: {
                    width: "100%",
                    display: "flex",
                    marginTop: 24,
                  },
                }}
                theme="tetriary"
              />
            </Box>
          </ReusableGlossBox>
        )}
      </Col>
    </Row>
  );
};

export default DigitalBackdropPricing;
